html,
body {
  cursor: default;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  user-select: none;
}

@media print {

  html,
  body {
    position: relative;
    overflow: visible;
  }
}